<template>
    <section>
        <div class="row mx-0 align-items-center py-2 px-3 border-bottom">
            {{ nombre_grupo }}
            <el-popover
            v-model="closeEditar"
            placement="bottom-end"
            class="p-0 ml-auto"
            width="300"
            trigger="click"
            >
                <div class="row mx-0 justify-center align-items-center position-relative" style="background-image: url('https://i.pinimg.com/736x/1c/27/d7/1c27d7cff2c7263ea6d14d875bcc9394.jpg');height:50px;">
                    <div class="ml-auto mr-2 bg-gr-general text-white rounded-circle d-middle-center cr-pointer" style="width:20px;height:20px;" @click=" closeEditar = false">
                        <i class="icon-cancel" />
                    </div>
                </div>
                <div class="row mx-0 mt-3 justify-center text-muted2 f-600 f-17">
                    Editar grupo de avatares
                </div>
                <ValidationObserver ref="validacion" v-slot="{ valid }">
                    <div class="row mx-0 my-4">
                        <div class="col px-5">
                            <label class="px-3">
                                Nombre del grupo
                            </label>
                            <ValidationProvider v-slot="{ errors }" rules="required|max:30" name="nombre grupo">
                                <el-input v-model="nombre_grupo" maxlength="30" show-word-limit />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 justify-center pb-3">
                        <div class="col-5 px-1">
                            <div class="bg-whitesmoke border cr-pointer p-2 d-middle-center br-6 f-17" @click=" closeEditar = false">
                                Cancelar
                            </div>
                        </div>
                        <div class="col-5 px-1">
                            <div class="bg-grr-red text-white cr-pointer p-2 d-middle-center br-6 f-17" @click="crear_grupo_avatar(valid)">
                                Aceptar
                            </div>
                        </div>
                    </div>
                </ValidationObserver>
                <el-tooltip slot="reference" class="item" effect="light" content="Editar" placement="bottom">
                    <div class="d-middle-center bg-grr-general text-white cr-pointer br-10 mr-3" style="width:35px;height:35px;">
                        <i class="icon-pencil-outline f-18" />
                    </div>
                </el-tooltip>
            </el-popover>
            <el-tooltip class="item" effect="light" content="Editar" placement="bottom">
                <div class="d-middle-center bg-grr-red text-white cr-pointer br-10" style="width:35px;height:35px;" @click="eliminarGrupo">
                    <i class="icon-trash-empty f-18" />
                </div>
            </el-tooltip>
        </div>
        <div class="row mx-0 p-3">
            <div class="m-2 rounded-circle cr-pointer bg-gr-general text-white d-middle-center" style="width:65px;height:65px;" @click="subirAvatar">
                <i class="icon-plus f-20" />
            </div>
            <el-popover
            v-for="(data, idx) in avatares" :key="idx"
            v-model="data.popover"
            placement="bottom"
            trigger="click"
            >
                <div class="row mx-0 p-1 px-2 br-20 hover-options justify-center" @click="editarAvatar(data)">
                    Editar
                </div>
                <div class="row mx-0 p-1 px-2 br-20 hover-options justify-center" @click="eliminarAvatar(data)">
                    Eliminar
                </div>
                <img
                slot="reference"
                class="rounded-circle m-2 bg-white obj-cover cr-pointer"
                :class="activo==idx?'border-general2':'border'"
                width="65" height="65"
                :src="data.imagen_firmada"
                @click="activo=idx"
                />
            </el-popover>
        </div>
        <modalSubirAvatar ref="modalSubirAvatar" @actualizar="get_avatares" />
        <modalEliminar ref="modalEliminarAvatar" titulo="Eliminar avatar" mensaje="¿Desea eliminar este avatar?" @eliminar="eliminar_avatar" />
        <modalEliminar ref="modalEliminarGrupo" titulo="Eliminar grupo" mensaje="¿Desea eliminar este grupo?" @eliminar="eliminar_avatar" />
    </section>
</template>

<script>
import Avatar from "~/services/avatares/index.js"

export default {
    components: {
        modalSubirAvatar: () => import('./modalSubirAvatar')
    },
    props: {
        idPadre: {
            type: Number,
            required: true,
            default: 0
        },
    },
    data(){
        return {
            closeEditar: false,
            buscar: '',
            nombre_grupo: '',
            activo: -1,
            id_eliminar: null,
            avatares: [
                { popover: false, imagen: 'https://www.clipartmax.com/png/middle/20-206419_cookie-clipart-png-transparent-galletas-del-monstruo-de-las-galletas.png' },
                { popover: false, imagen: 'https://www.clipartmax.com/png/middle/20-206419_cookie-clipart-png-transparent-galletas-del-monstruo-de-las-galletas.png' }
            ]
        }
    },
    watch: {
        idPadre(){
            this.closeEditar = false
            this.get_avatares()
        }  
    },
    mounted(){
        this.get_avatares()
    },
    methods: {
        async get_avatares(){
            try {
                const { data } =  await Avatar.get_avatares(this.idPadre)
                data.avatares.map(a =>{
                    a.popover = false
                })
                this.avatares = data.avatares
                this.nombre_grupo = data.nombre_grupo
                this.$emit('actualizar')

            } catch (error){
                this.error_catch(error)
            }
        },
        async crear_grupo_avatar(valid){
            try {
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                let model = {
                    id: this.idPadre,
                    nombre: this.nombre_grupo
                }
                const { data } =  await Avatar.crear_grupo_avatar(model)
                this.closeEditar = false
                this.nombre_grupo = data.nombre_grupo
                this.$refs.validacion.reset()
                this.$emit('actualizar')

            } catch (error){
                this.error_catch(error)
            }
        },
        subirAvatar(){
            let datos = {
                id: null,
                id_padre: this.idPadre,
                imagen: null
            }
            this.activo = null
            this.$refs.modalSubirAvatar.toggle(datos)
        },
        editarAvatar(data){
            let datos = {
                id: data.id,
                id_padre: data.id_padre,
                imagen: data.imagen
            }
            data.popover = false
            this.$refs.modalSubirAvatar.toggle(datos)
        },
        eliminarAvatar(data){
            this.id_eliminar = data.id
            data.popover = false
            this.$refs.modalEliminarAvatar.toggle()
        },
        eliminarGrupo(){
            this.id_eliminar = this.idPadre
            this.$refs.modalEliminarGrupo.toggle()
        },
        async eliminar_avatar(){
            try {
                const { data } =  await Avatar.eliminar_avatar(this.id_eliminar)
                this.get_avatares()
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.$emit('actualizar')

            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.text-orange{
    color: #FF612B;
}
.text-morado{
    color: #9508EB;
}
.alto{
    height: calc(100vh - 64px);
}
.border-general2{
    border: 2px solid var(--color-general2)
}
</style>
